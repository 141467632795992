import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FieldTextInput, Form, NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

import css from './ContactUsForm.module.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { postContactRequest } from '../../../util/api';

const ContactUsFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        // handleSubmit,
        // inProgress,
        intl,
        invalid,
        form,
        values
      } = fieldRenderProps;

      const [loading, setLoading] = useState(false)
      const [error, setError] = useState(null)
      const [success, setSuccess] = useState(null)

      const sendContactRequest = async (e) => {
        e.preventDefault()
        const { email, name, message, telephoneNumber } = values
        try {
          setLoading(true)
          setError(null)
          setSuccess(null)
          const response = await postContactRequest({ email, name, message, telephoneNumber })
          if (response.success) {
            setSuccess(`Your contact request has been received`)
            form.restart()
          } else {
            setError("Unable to send request")
          }
          setLoading(false)
        } catch (err) {
          setLoading(false)
          setError("Server Error")
        }
      }

      const nameLabel = intl.formatMessage({
        id: 'ContactUsForm.nameLabel',
      });
      const namePlaceholder = intl.formatMessage({
        id: 'ContactUsForm.namePlaceholder',
      });

      // email
      const emailLabel = intl.formatMessage({
        id: 'ContactUsForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ContactUsForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactUsForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ContactUsForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const telLabel = intl.formatMessage({
        id: 'ContactUsForm.telLabel',
      });
      const telPlaceholder = intl.formatMessage({
        id: 'ContactUsForm.telPlaceholder',
      });

      const messageLabel = intl.formatMessage({
        id: 'ContactUsForm.messageLabel',
      });
      const messagePlaceholder = intl.formatMessage({
        id: 'ContactUsForm.messagePlaceholder',
      });

      const description = intl.formatMessage({
        id: 'contactUsForm.description',
      });

      const classes = classNames(rootClassName || css.body, className);
      // const submitInProgress = inProgress;
      const submitDisabled = !(values.email && values.message && values.name);

      return (
        <Form className={classes} onSubmit={sendContactRequest}>
          <div>
            <FieldTextInput
              type="name"
              id={formId ? `${formId}.name` : 'name'}
              name="name"
              autoComplete="name"
              label={nameLabel}
              placeholder={namePlaceholder}
            />
            <FieldTextInput
              className={css.password}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.password}
              type="telephoneNumber"
              id={formId ? `${formId}.telephoneNumber` : 'telephoneNumber'}
              name="telephoneNumber"
              label={telLabel}
              placeholder={telPlaceholder}
            />
            <FieldTextInput
              className={css.password}
              type="textarea"
              id={formId ? `${formId}.message` : 'message'}
              name="message"
              label={messageLabel}
              placeholder={messagePlaceholder}
            />
            <div className={css.description}>{description}</div>
            {/* <div className={css.recaptchaStyle}>
              <Field
                name="recaptcha"
                validate={validators.required(
                  intl.formatMessage({
                    id: 'RecaptCha',
                  })
                )}
              >
                {props => (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
            </div> */}
          </div>
          <div className={css.bottomWrapper}>
            {error && <span className={css.error}>{error}</span>}
            {success && <span className={css.success}>{success}</span>}
            <PrimaryButton
              type="submit"
              inProgress={loading}
              disabled={submitDisabled || loading}
              className={css.submitButton}
            >
              <FormattedMessage id="ContactUsForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  // inProgress: false,
};

const { string, bool } = PropTypes;

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  // inProgress: bool,
  intl: intlShape.isRequired,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);
ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
