import React, { useState } from 'react';
import closeAccordion from '../../assets/closeAccordion.svg';
import defaultAccordion from '../../assets/openAccordion.svg';
import css from './Accordion.module.css';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? true : false;

    return (
      <div
        key={index}
        className={active ? css.accordionItemActive : css.accordionItem}
        onClick={() => onTitleClick(index)}
      >
        <div className={active ? css.accordionActive : css.accordionTitle}>
          <div>{item.title.content}</div>

          {active ? (
            <img src={closeAccordion} className={css.closeAccordion} />
          ) : (
            <img src={defaultAccordion} className={css.defaultAccordion} />
          )}
        </div>
        <div className={active ? css.accordionActiveContent : css.accordionContent}>
          {item.text.content}
        </div>
      </div>
    );
  });

  return <div className={css.accordion}>{renderedItems}</div>;
};

export default Accordion;
