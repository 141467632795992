import React from 'react';

import { Button, IconSpinner, LayoutComposer, NamedLink } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from './Field';

import SectionBuilder, {
  DEFAULT_CLASSES,
  defaultSectionComponents,
} from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import TelSVG from '../../assets/call.svg';
import HelpUsSVG from '../../assets/helpFrame.svg';
import EmailSVG from '../../assets/mail.svg';
import Accordion from '../../components/Accordion/Accordion.js';
import ContactUsForm from '../AuthenticationPage/ContactUsForm/ContactUsForm.js';
import CustomHeaderPage from '../CustomHeaderPage/CustomHeaderPage.js';
import css from './PageBuilder.module.css';
import CharityLogos from '../CustomLandingPage/CharityLogos/CharityLogos.js';
import AboutUsPNG from '../../assets/aboutus.jpeg';
import SocialImpactPNG from '../../assets/social-impact.png';
import Labayk1PNG from '../../assets/labayk1.png';
import Labayk2PNG from '../../assets/labayk2.jpeg';
import Labayk3PNG from '../../assets/labayk3.jpeg';
import Labayk4PNG from '../../assets/labayk4.jpeg';
import Labayk5PNG from '../../assets/labayk5.png';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    currentPage,
    pageId,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  if (pageId === 'faq') {
    return (
      <CustomHeaderPage pageId="FAQ" description="Frequently asked questions">
        <div className={css.accordionLayout}>
          {sections && sections[0]?.blocks && <Accordion items={sections[0].blocks} />}
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'contact') {
    return (
      <CustomHeaderPage pageId="Contact Us" description="Feel free to contact Us">
        <div className={css.contact}>
          <div className={css.contactUsHelp}>
            <div className={css.contactUsTitle}>How Can We Help?</div>
            <div className={css.contactUsDescription}>
              Do you have a question or are you interested in working with Labayk? Just fill out the
              form fields below.
            </div>
            <img src={HelpUsSVG} className={css.helpIconStyle} />
            <div>
              <div className={css.contactUsTitleBottom}>Contact Us</div>
              <div className={css.contactEmailAndTel}>
                <img src={EmailSVG} className={css.contactMargin} />
                <a href="mailto:info@labayk.com" style={{ color: 'white' }}>
                  info@labayk.com
                </a>
              </div>
              <div className={css.contactEmailAndTel}>
                <img src={TelSVG} className={css.contactMargin} />
                <div onClick={() => (window.location.href = 'tel:+44(0)2030929656')}>
                  +44 (0) 20 3092 9656
                </div>
              </div>
            </div>
          </div>
          <ContactUsForm onSubmit={() => {}} className={css.contactUsForm} />
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'about') {
    return (
      <CustomHeaderPage pageId="About Us" description="At Your Service">
        <div className={css.section}>
          <div className={css.contentWrapper}>
            <h2>Charities we support</h2>
            {/* <div className={css.aboutUsDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</div> */}
            <br />
            <CharityLogos />
            <div className={css.aboutUsFlex}>
              <div className={css.imageContent}>
                <img className={css.aboutUsImage} src={AboutUsPNG} alt="Tanweer Khan" />
              </div>

              <div className={css.aboutUsContent} style={{ alignContent: 'center' }}>
                <div className={css.aboutUsTitle}>
                  Labayk is the brainchild of economist Tanweer Khan.
                </div>
                <div className={css.aboutUsDescription}>
                  Having seen the explosion in eCommerce and online shopping, Tanweer felt that big
                  corporations were ripping sellers and buyers off with huge commissions and fees to
                  boost their profits and line the pockets of their shareholders and investors.
                </div>
                <div className={css.aboutUsDescription}>
                  Tanweer believes that the internet belongs to everybody, and we should use it to
                  make a positive difference in the lives of those that are less fortunate.
                </div>
                <div className={css.aboutUsDescription}>
                  That’s why Labayk donates all its profits to charities that do good work.
                </div>

                <div className={css.aboutUsDescription}>
                  Join us in making Labayk ‘A Force For Good’
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'social-impact') {
    return (
      <CustomHeaderPage pageId="Social Impact" description="What will be Labayk’s Social Impact?">
        <div className={css.section}>
          <div className={css.contentWrapperLeft}>
            <img className={css.aboutUsImage} src={SocialImpactPNG} alt="Social impact" />

            <div className={css.socialImpactTitle}>What will be Labayk’s Social Impact?</div>
            <div className={css.socialImpactDescription} style={{ marginTop: '32px' }}>
              Labayk aims to empower people around the world, by donating money to help feed the
              hungry, treat the sick, teach the poor, clothe the destitute and house the homeless.
            </div>

            <div className={css.socialImpactDescription}>
              Labayk recognises that an online marketplace’s biggest and most valuable assets are
              its users.
            </div>

            <div className={css.socialImpactDescription}>
              As such, Labayk will be donating ALL of its profits to worthy causes in order to help
              people and causes that are deserving of its support.
            </div>

            <div className={css.socialImpactDescription}>
              In particular, this will involve Labayk working with charities and NGOs that are
              focusing their efforts on food provision, medical care, education, clothing and
              housing.
            </div>
            <div className={css.socialImpactDescription}>
              Users will decide themselves which charitable projects Labayk will support by
              nominating a charity each time they make a purchase or sell an item.
            </div>
            <div className={css.socialImpactDescription}>
              The more people buy and sell on Labayk, the more money we can donate to charitable
              causes.The only way we will achieve that is by having more buyers and sellers using
              Labayk.
            </div>
            <div className={css.socialImpactDescription}>
              Please invite all your friends and family to sign up to Labayk.
            </div>
            <div className={css.socialImpactDescriptionII}>
              Together we can be ‘A Force For Change’.
            </div>
            <div className={css.contentWrapper}>
              <NamedLink name="SignupPage">
                <Button className={css.aboutUsButton}>Join us now</Button>
              </NamedLink>
            </div>
          </div>
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'what-is-labayk') {
    return (
      <CustomHeaderPage pageId="What Is Labayk" description="Labayk">
        <div className={css.section}>
          <div className={css.contentWrapper}>
            {/* 1 */}
            <div className={css.labaykFlex}>
              <div className={css.imageContent}>
                <img className={css.aboutUsImage} src={Labayk1PNG} alt="Labayk1PNG" />
              </div>

              <div className={css.aboutUsContent}>
                <div className={css.aboutUsTitle}>What is Labayk</div>
                <div className={css.labaykDescription}>
                  Labayk is an online marketplace fuelled by a vibrant community of both sellers and
                  buyers. Serving as your ultimate shopping destination, our platform offers
                  everything you need, from home essentials to fashion finds and thoughtful gifts.
                </div>

                <div className={css.labaykDescription}>
                  What sets Labayk apart is our unique approach – we're not driven by profit. While
                  we cover our operational expenses, every single penny of our profits goes towards
                  charitable causes chosen by our users.
                </div>
                <div className={css.labaykDescription}>
                  In a world dominated by profit-hungry corporations, we're on a mission to
                  democratise e-commerce. Through the power of the internet, we strive to make a
                  positive impact by supporting the most vulnerable members of our society.
                </div>

                <div className={css.labaykDescriptionII}>
                  Join Labayk – Where Shopping Creates Change.
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
            </div>
            {/* 2 */}
            <div className={css.labaykFlexRevert}>
              <div className={css.aboutUsContent}>
                <div className={css.aboutUsTitle}>How does Labayk work?</div>
                <div className={css.labaykDescription}>
                  Experience the convergence of buyers and sellers on our dynamic online
                  marketplace.
                </div>
                <div className={css.labaykDescription}>
                  Enter the digital realm of Labayk, where individuality thrives through purchasing,
                  crafting, and vending.
                </div>
                <div className={css.labaykDescription}>
                  Explore our diverse range of shops and products, filtering by location, price,
                  category, and ratings.
                </div>
                <div className={css.labaykDescription}>
                  Whether you prefer guest checkout or opt for the convenience of creating a Labayk
                  account, you can easily follow your beloved shops and provide valuable feedback.
                </div>
                <div className={css.labaykDescription}>
                  For sellers, seize the opportunity to establish your shop from any location,
                  connecting with an engaged audience seeking innovative ideas and unique
                  discoveries
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
              <div className={css.imageContent}>
                <img className={css.aboutUsImage} src={Labayk2PNG} alt="Labayk1PNG" />
              </div>
            </div>
            {/* 3 */}
            <div className={css.labaykFlex}>
              <div className={css.imageContent}>
                <img className={css.aboutUsImage} src={Labayk3PNG} alt="Labayk1PNG" />
              </div>

              <div className={css.aboutUsContent}>
                <div className={css.aboutUsTitle}>Why choose Labayk?</div>
                <div className={css.labaykDescription}>
                  When you shop with Labayk, you're not just making a purchase – you're investing in
                  communities, empowering creative entrepreneurs to continue crafting meaningful and
                  inspiring items that add a touch of magic to life's moments, big and small.
                </div>

                <div className={css.labaykDescription}>
                  At Labayk, our mission is simple: to be a force for good.
                </div>
                <div className={css.labaykDescription}>
                  Labayk is dedicated to assisting society's most vulnerable individuals, those who
                  have been overlooked by politicians, governments, and global leaders.
                </div>
                <div className={css.labaykDescription}>
                  Through collaborations with esteemed charities, we aim to restore dignity to the
                  lives of those who are being marginalised and left behind.
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
            </div>
            {/* 4 */}
            <div className={css.labaykFlexRevert}>
              <div className={css.aboutUsContent}>
                <div className={css.aboutUsTitle}>How do I buy and sell on Labayk?</div>
                <div className={css.labaykDescription}>
                  Navigating Labayk is a breeze – it's straightforward, convenient, and fast.
                </div>

                <div className={css.labaykDescription}>
                  Simply peruse the latest listings and proceed to checkout as a guest.
                </div>
                <div className={css.labaykDescription}>
                  Alternatively, sign up to create an account where you can save your favourite
                  items, communicate with sellers, or even establish your own shop to sell your
                  creations.
                </div>
                <div className={css.labaykDescription}>
                  Discover more about Shopping on Labayk or kickstart Your Selling Journey with us
                  today.
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
              <div className={css.imageContent}>
                <img className={css.aboutUsImage} src={Labayk4PNG} alt="Labayk1PNG" />
              </div>
            </div>
            {/* 5 */}
            <div className={css.labaykFlex}>
              <div className={css.imageContentLimitHeight}>
                <img className={css.aboutUsImage} src={Labayk5PNG} alt="Labayk1PNG" />
              </div>

              <div className={css.aboutUsContent}>
                <div className={css.aboutUsTitle}>How can I trust Labayk?</div>
                <div className={css.labaykDescription}>
                  Rest assured with the Labayk Purchase Protection Programme, ensuring a worry- Free
                  shopping experience.
                </div>

                <div className={css.labaykDescription}>
                  Our star rating system highlights top-rated items, while Labayk's Picks feature
                  editor- approved products.
                </div>
                <div className={css.labaykDescription}>
                  Easily refine your search by exploring the Star Seller category or Labayk's Picks
                  to discover the highest-rated shops and products.
                </div>
                <div className={css.labaykDescription}>
                  Shop with confidence knowing you're supported by Labayk's reliable protection
                  programme.
                </div>

                <NamedLink name="SignupPage">
                  <Button className={css.aboutUsButton}>Join us now</Button>
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'purchase-protection-programme') {
    return (
      <CustomHeaderPage pageId="Purchase Protection Programme" description="Labayk">
        <div className={css.section}>
          <div className={css.contentWrapperLeft} style={{ maxWidth: '900px' }}>
            <div className={css.purchaseProgramTitle}>Purchase Protection Programme</div>
            <div className={css.purchaseDescription} style={{ marginTop: '32px' }}>
              The Labayk Purchase Protection Programme is designed to ensure that you can
              confidently buy and sell items on Labayk.
            </div>
            <div className={css.purchaseDescription}>
              Therefore, every seller on Labayk is verified before they are allowed to post a
              listing on our marketplace.
            </div>
            <div className={css.purchaseDescription}>
              Once a transaction is made on Labayk, the payment made by the buyer is held in an
              escrow account with Stripe.
            </div>
            <div className={css.purchaseDescription}>
              Stripe is one the world’s leading and securest methods of payment.
            </div>
            <div className={css.purchaseDescription}>
              By purchasing an item on Labayk, your transaction is covered by our Purchase
              Protection Programme which will apply if you do not receive your item, if it is
              damaged or if it does not match the description provided by the seller.
            </div>
            <div className={css.purchaseDescription}>
              If something goes wrong, let us know within 5 days of delivery.
            </div>
            <div className={css.purchaseDescription}>
              We will only release the payment to the seller 7 days after we have confirmation that
              the item has been delivered.
            </div>
          </div>
        </div>
      </CustomHeaderPage>
    );
  }

  if (pageId === 'disclaimer') {
    return (
      <CustomHeaderPage pageId="Disclaimer" description="Feel free to contact Us">
        <div className={css.contentWrapper}>
          <SectionBuilder sections={sections} options={options} />
        </div>
      </CustomHeaderPage>
    );
  }

  if (
    pageId === 'terms-of-service' ||
    (sections &&
      sections.length > 0 &&
      sections[0].sectionName === 'Terms of Service content section')
  ) {
    return (
      <CustomHeaderPage pageId="Terms and Conditions" description="Labayk">
        <div className={css.contentWrapper}>
          <SectionBuilder sections={sections} options={options} />
        </div>
      </CustomHeaderPage>
    );
  }

  if (
    pageId === 'privacy-policy' ||
    (sections &&
      sections.length > 0 &&
      sections[0].sectionName === 'Privacy Policy content section')
  ) {
    return (
      <CustomHeaderPage pageId="Privacy Policy" description="Labayk">
        <div className={css.contentWrapper}>
          <SectionBuilder sections={sections} options={options} />
        </div>
      </CustomHeaderPage>
    );
  }

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
              </Topbar>
              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <SectionBuilder sections={sections} options={options} />
                )}
              </Main>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, SectionBuilder, StaticPage };

export default PageBuilder;
