import React from 'react';

export const twitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" id="body_1" width="18" height="16">
      <g transform="matrix(0.06 0 0 0.05904059 0 0)">
        <path d="M236 0L282 0L181 115L299 271L206.4 271L133.9 176.2L50.899994 271L4.899994 271L111.899994 148L-1.1000061 0L93.799995 0L159.29999 86.6L236 0zM219.9 244L245.4 244L80.399994 26L52.999992 26z"/>
      </g>
    </svg>
  );
};
