import { Header, LayoutSingleColumn, Page } from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

function CustomHeaderPage(props) {
  const { pageId, description, children } = props;
  return (
    <Page title={pageId} scrollingDisabled={false}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer />
          </>
        }
        footer={<FooterContainer hasNewsletter />}
        topBarBackgroundColor="transparent"
      >
        <Header title={pageId} description={description} />

        {children}
        
      </LayoutSingleColumn>
    </Page>
  );
}

export default CustomHeaderPage;
